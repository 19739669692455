import React, { useEffect } from "react"
import Layout from "../theme/layout"
import styled from "styled-components"
import { useStaticQuery, graphql } from "gatsby"
import { navigate } from "gatsby-link"

const ThankYou = () => {
  useEffect(() => {
    setTimeout(() => {
      navigate("/")
    }, 5000)
  }, [])

  const { headerContent } = useStaticQuery(HEADER_QUERY)

  const nodesHeaderContent = headerContent?.nodes[0]

  return (
    <Layout headerContent={nodesHeaderContent}>
      <ThankYouContainerStyling>
        <h2>Thank you for your submission!</h2>
        <p>We will contact you soon!</p>
        <p>You'll automatically be redirected to the home page.</p>
        <StyledNavigationButton>
          <a href="/" rel="noreferrer">
            Click here if it doesn't happen
          </a>
        </StyledNavigationButton>
      </ThankYouContainerStyling>
    </Layout>
  )
}

export default ThankYou

// ===============
//      STYLES
// ===============

const ThankYouContainerStyling = styled.div`
  text-align: center;
  padding: 200px 5% 100px 5%;
`

const StyledNavigationButton = styled.button`
  color: ${({ theme }) => theme.colors.navy};
  height: 50px;
  border: 1px solid ${({ theme }) => theme.colors.navy};
  border-radius: 5px;
  background: white;
  min-width: 35%;
  align-self: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 25px;
  margin: 50px auto 0 auto;

  &:hover {
    background: ${({ theme }) => theme.colors.red};
    color: white;
    border: none;
  }
  a {
    text-decoration: none;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
`

// ===============
//      QUERY
// ===============

const HEADER_QUERY = graphql`
  {
    headerContent: allStrapiHeader {
      nodes {
        siteLogo {
          localFile {
            publicURL
          }
        }
      }
    }
  }
`
